export enum EquivalentSpecsNameEnum {
  din = "DIN (Germany)",
  digitalSystem = "Digital System (Germany)",
  ansi = "ANSI/AA (USA)",
  sae = "SAE (USA)",
  astm = "ASTM (USA)",
  cda = "CDA (USA)",
  iso = "ISO (Int.)",
  en = "EN (EU)",
  gb = "GB (China)",
  bs = "BS (UK)",
  afnor = "AFNOR (France)",
  une = "UNE (Spain)",
  uns = "UNS",
  jish = "JISh (Japan)",
  csa = "CSA (Canada)",
  sis = "SIS (Sweden)",
  uni = "UNI (Italy)",
}
