import { createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57c51f21"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-form" }
const _hoisted_2 = { class: "add-equivalent-specs-modal-actions costing-parameters-action-modal-actions main-modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "add-equivalent-specs-modal costing-parameters-action-modal",
    preset: "dialog",
    "show-icon": false,
    title: "Equivalent specs"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
      _createVNode($setup["NForm"], {
        class: "costing-parameters-action-form",
        ref: "formRef",
        model: $setup.form
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["NFormItem"], {
              required: "",
              path: "name",
              label: "Material name (grade)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $props.materialName,
                  placeholder: "Enter",
                  disabled: ""
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              required: "",
              path: "materialGroupId",
              label: "Material group"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["SelectableTableCell"], {
                  value: $props.materialGroup,
                  options: $props.materialGroupOptions,
                  filterable: "",
                  addable: "",
                  selectInForm: "",
                  disabled: ""
                }, null, 8, ["value", "options"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "din",
              label: "DIN (Germany)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.din,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.din) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[1] || (_cache[1] = ($event: any) => ($setup.form.din = $setup.onCleanExtraSpaces($setup.form.din)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "digitalSystem",
              label: "Digital System (Germany)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.digitalSystem,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.digitalSystem) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[3] || (_cache[3] = ($event: any) => ($setup.form.digitalSystem = $setup.onCleanExtraSpaces($setup.form.digitalSystem)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "ansi",
              label: "ANSI/AA (USA)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.ansi,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (($setup.form.ansi) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[5] || (_cache[5] = ($event: any) => ($setup.form.ansi = $setup.onCleanExtraSpaces($setup.form.ansi)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "sae",
              label: "SAE (USA)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.sae,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (($setup.form.sae) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[7] || (_cache[7] = ($event: any) => ($setup.form.sae = $setup.onCleanExtraSpaces($setup.form.sae)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "astm",
              label: "ASTM (USA)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.astm,
                  "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => (($setup.form.astm) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[9] || (_cache[9] = ($event: any) => ($setup.form.astm = $setup.onCleanExtraSpaces($setup.form.astm)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "cda",
              label: "CDA (USA)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.cda,
                  "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => (($setup.form.cda) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[11] || (_cache[11] = ($event: any) => ($setup.form.cda = $setup.onCleanExtraSpaces($setup.form.cda)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "iso",
              label: "ISO (Int.)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.iso,
                  "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => (($setup.form.iso) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[13] || (_cache[13] = ($event: any) => ($setup.form.iso = $setup.onCleanExtraSpaces($setup.form.iso)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "en",
              label: "EN (EU)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.en,
                  "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => (($setup.form.en) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[15] || (_cache[15] = ($event: any) => ($setup.form.en = $setup.onCleanExtraSpaces($setup.form.en)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "gb",
              label: "GB (China)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.gb,
                  "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => (($setup.form.gb) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[17] || (_cache[17] = ($event: any) => ($setup.form.gb = $setup.onCleanExtraSpaces($setup.form.gb)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "bs",
              label: "BS (UK)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.bs,
                  "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => (($setup.form.bs) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[19] || (_cache[19] = ($event: any) => ($setup.form.bs = $setup.onCleanExtraSpaces($setup.form.bs)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "afnor",
              label: "AFNOR (France)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.afnor,
                  "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => (($setup.form.afnor) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[21] || (_cache[21] = ($event: any) => ($setup.form.afnor = $setup.onCleanExtraSpaces($setup.form.afnor)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "une",
              label: "UNE (Spain)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.une,
                  "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => (($setup.form.une) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[23] || (_cache[23] = ($event: any) => ($setup.form.une = $setup.onCleanExtraSpaces($setup.form.une)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "uns",
              label: "UNS"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.uns,
                  "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => (($setup.form.uns) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[25] || (_cache[25] = ($event: any) => ($setup.form.uns = $setup.onCleanExtraSpaces($setup.form.uns)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "jish",
              label: "JISh (Japan)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.jish,
                  "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => (($setup.form.jish) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[27] || (_cache[27] = ($event: any) => ($setup.form.jish = $setup.onCleanExtraSpaces($setup.form.jish)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "csa",
              label: "CSA (Canada)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.csa,
                  "onUpdate:value": _cache[28] || (_cache[28] = ($event: any) => (($setup.form.csa) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[29] || (_cache[29] = ($event: any) => ($setup.form.csa = $setup.onCleanExtraSpaces($setup.form.csa)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "sis",
              label: "SIS (Sweden)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.sis,
                  "onUpdate:value": _cache[30] || (_cache[30] = ($event: any) => (($setup.form.sis) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[31] || (_cache[31] = ($event: any) => ($setup.form.sis = $setup.onCleanExtraSpaces($setup.form.sis)))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              path: "uni",
              label: "UNI (Italy)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.uni,
                  "onUpdate:value": _cache[32] || (_cache[32] = ($event: any) => (($setup.form.uni) = $event)),
                  placeholder: "Enter",
                  onBlur: _cache[33] || (_cache[33] = ($event: any) => ($setup.form.uni = $setup.onCleanExtraSpaces($setup.form.uni)))
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["NButton"], {
          class: "update-button n-button-save medium-button",
          type: "info",
          onClick: $setup.onAddEquivalentSpecs
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.isUpdate ? 'Update':'Add'), 1)
          ]),
          _: 1
        }),
        _createVNode($setup["NButton"], {
          class: "medium-button n-button-cancel",
          type: "tertiary",
          onClick: _cache[34] || (_cache[34] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}