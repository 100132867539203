import { createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-786c9834"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "wrapper",
  ref: "modalRef"
}
const _hoisted_2 = { class: "main-form" }
const _hoisted_3 = { class: "main-form" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = {
  key: 0,
  class: "cost-and-lead-time-info"
}
const _hoisted_6 = { class: "add-raw-material-modal-actions costing-parameters-action-modal-actions main-modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "add-raw-material-modal costing-parameters-action-modal",
    preset: "dialog",
    "show-icon": false,
    title: "Add Raw Material"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["NForm"], {
          class: "add-raw-material-form costing-parameters-action-form",
          ref: "formRef",
          model: $setup.form
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode($setup["NFormItem"], {
                required: "",
                path: "name",
                label: "Material name (grade)",
                rule: $setup.materialNameValidation()
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["NInput"], {
                    class: "tabbable-focusable",
                    value: $setup.form.name,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.name) = $event)),
                    placeholder: "Enter",
                    onKeydown: _withKeys($setup.onKeydownTab, ["tab"]),
                    onBlur: _cache[1] || (_cache[1] = ($event: any) => ($setup.form.name = $setup.onCleanExtraSpaces($setup.form.name)))
                  }, null, 8, ["value", "onKeydown"])
                ]),
                _: 1
              }, 8, ["rule"]),
              _createVNode($setup["NFormItem"], {
                required: "",
                path: "materialGroupId",
                label: "Material group",
                rule: $setup.requiredRules('Material group', 'materialGroupId')
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["SelectableTableCell"], {
                    value: $setup.form.materialGroupId,
                    options: $setup.materialGroupOptions,
                    onUpdateValue: (data) => $setup.form.materialGroupId = data,
                    filterable: "",
                    addable: "",
                    selectInForm: "",
                    onAddOption: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('addOption', $event))),
                    onDeleteOption: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('deleteOption', $event))),
                    onChangeOption: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('changeOption', $event))),
                    onKeydownTab: $setup.onKeydownTabHandler
                  }, null, 8, ["value", "options", "onUpdateValue", "onKeydownTab"])
                ]),
                _: 1
              }, 8, ["rule"]),
              _createVNode($setup["NFormItem"], {
                path: "alternativeMaterials",
                label: "Alternative materials"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["SelectableTableCell"], {
                    values: $setup.form.alternativeMaterials,
                    options: $props.materialAlternativesOptions,
                    onUpdateValue: (data) => $setup.form.alternativeMaterials = data,
                    filterable: "",
                    multiple: "",
                    selectInForm: "",
                    onKeydownTab: $setup.onKeydownTabHandler
                  }, null, 8, ["values", "options", "onUpdateValue", "onKeydownTab"])
                ]),
                _: 1
              }),
              _createVNode($setup["NFormItem"], {
                required: "",
                path: "shapes",
                label: "Shapes",
                rule: $setup.requiredRules('Shapes', 'shapes')
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["SelectableTableCell"], {
                    values: $setup.form.shapes,
                    options: $props.shapesOptions,
                    onUpdateValue: (data) => $setup.form.shapes = data,
                    multiple: "",
                    selectInForm: "",
                    onKeydownTab: $setup.onKeydownTabHandler
                  }, null, 8, ["values", "options", "onUpdateValue", "onKeydownTab"])
                ]),
                _: 1
              }, 8, ["rule"]),
              _createVNode($setup["NFormItem"], {
                required: "",
                path: "density",
                label: "Density",
                rule: $setup.numberFieldValidation('Density')
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["NInput"], {
                    class: "tabbable-focusable",
                    value: $setup.form.density,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (($setup.form.density) = $event)),
                    placeholder: "Enter",
                    onKeydown: _withKeys($setup.onKeydownTab, ["tab"]),
                    onBlur: _cache[6] || (_cache[6] = ($event: any) => ($setup.form.name = $setup.onCleanExtraSpaces($setup.form.name)))
                  }, null, 8, ["value", "onKeydown"])
                ]),
                _: 1
              }, 8, ["rule"])
            ])
          ]),
          _: 1
        }, 8, ["model"]),
        _createVNode($setup["NForm"], {
          class: "mfr-regions-form costing-parameters-action-form",
          ref: "formMfrRegionsRef",
          model: $setup.formMfrRegions
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.formMfrRegions, (value, key) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(key) + " manufacturing region", 1),
                _createVNode($setup["NFormItem"], {
                  path: `${key}.available`,
                  label: "Availability"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["SelectableTableCell"], {
                      value: $setup.formMfrRegions[key].available ? 'Yes' : 'No',
                      options: $props.availabilityOptions,
                      onUpdateValue: (data) => $setup.formMfrRegions[key].available = data,
                      selectInForm: "",
                      onKeydownTab: $setup.onKeydownTabHandler
                    }, null, 8, ["value", "options", "onUpdateValue", "onKeydownTab"])
                  ]),
                  _: 2
                }, 1032, ["path"]),
                (value.available)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode($setup["NFormItem"], {
                        path: `${key}.cost`,
                        required: "",
                        label: `Cost per Kg (${$setup.formMfrRegions[key].mfrRegionCurrencySign})`,
                        rule: $setup.numberFieldValidation(`${key} cost`)
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["NInput"], {
                            class: "tabbable-focusable",
                            value: $setup.formMfrRegions[key].cost,
                            "onUpdate:value": ($event: any) => (($setup.formMfrRegions[key].cost) = $event),
                            placeholder: "Enter",
                            onBlur: ($event: any) => ($setup.formMfrRegions[key].cost = $setup.onCleanExtraSpaces($setup.formMfrRegions[key].cost)),
                            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
                          }, {
                            prefix: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.formMfrRegions[key].mfrRegionCurrencySign), 1)
                            ]),
                            _: 2
                          }, 1032, ["value", "onUpdate:value", "onBlur", "onKeydown"])
                        ]),
                        _: 2
                      }, 1032, ["path", "label", "rule"]),
                      _createVNode($setup["NFormItem"], {
                        path: `${key}.leadTime`,
                        required: "",
                        label: "Lead time (Days)",
                        rule: $setup.numberFieldValidation(`${key} lead time`)
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["NInput"], {
                            class: "tabbable-focusable",
                            value: $setup.formMfrRegions[key].leadTime,
                            "onUpdate:value": ($event: any) => (($setup.formMfrRegions[key].leadTime) = $event),
                            placeholder: "Enter",
                            onBlur: ($event: any) => ($setup.formMfrRegions[key].leadTime = $setup.onCleanExtraSpaces($setup.formMfrRegions[key].leadTime)),
                            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
                          }, null, 8, ["value", "onUpdate:value", "onBlur", "onKeydown"])
                        ]),
                        _: 2
                      }, 1032, ["path", "rule"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 256))
          ]),
          _: 1
        }, 8, ["model"]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode($setup["NButton"], {
            class: "update-button n-button-save medium-button tabbable-focusable",
            type: "info",
            onClick: $setup.onAddMaterial,
            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
          }, {
            default: _withCtx(() => [
              _createTextVNode("Add")
            ]),
            _: 1
          }, 8, ["onKeydown"]),
          _createVNode($setup["NButton"], {
            class: "medium-button n-button-cancel tabbable-focusable",
            type: "tertiary",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('close'))),
            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }, 8, ["onKeydown"])
        ])
      ], 512)
    ]),
    _: 1
  }))
}