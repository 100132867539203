import { requiredMessage } from "@/composables/validationMessages";
import { FormItemRule } from "naive-ui";

const requiredRules = (
  field: string,
  key = "",
  required = true
): FormItemRule => {
  return {
    required,
    ...(key
      ? {
          key,
        }
      : undefined),
    validator(_rule: FormItemRule, value: string): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        if (required && !value) {
          reject(Error(requiredMessage(field)));
        } else {
          resolve();
        }
      });
    },
    trigger: ["input", "blur", "change"],
  };
};

export default requiredRules;
