import { Ref } from "vue";
import { AxiosResponse } from "axios";
import useBaseCostAvailabilityByMfrRegion from "@/composables/costing-parameters/useBaseCostAvailabilityByMfrRegion";
import useBaseCostMethods from "@/composables/costing-parameters/useBaseCostMethods";
import useBaseCostCostingByMfrRegion from "@/composables/costing-parameters/useBaseCostCostingByMfrRegion";
import useBaseCostLeadTimeByMfrRegion from "@/composables/costing-parameters/useBaseCostLeadTimeByMfrRegion";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";
import AvailabilityInterface from "@/types/admin-parameters/costing-parameters/AvailabilityInterface";

export default (
  data: Ref<AvailabilityInterface[]>,
  keyField: string,
  addRequest: (payload: BaseCostInterface[]) => Promise<AxiosResponse>,
  changeRequest: (payload: BaseCostInterface) => Promise<AxiosResponse>,
  onClickCostCell: () => void,
  onClickLeadTimeCell: () => void
) => {
  const {
    baseCostValidationError,
    isValidMfrRegionOption,
    getDataIndexes,
    getMfrRegionOptions,
    onUpdateTable,
  } = useBaseCostMethods(data);

  const { onUpdateAvailabilityValue, onUpdateAvailability } =
    useBaseCostAvailabilityByMfrRegion(
      data,
      keyField,
      addRequest,
      changeRequest,
      onClickCostCell
    );

  const { onUpdateCost, onUpdateCostValue } = useBaseCostCostingByMfrRegion(
    data,
    keyField,
    addRequest,
    changeRequest,
    onClickLeadTimeCell
  );

  const { onUpdateLeadTimeValue, onUpdateLeadTime } =
    useBaseCostLeadTimeByMfrRegion(
      data,
      keyField,
      addRequest,
      changeRequest,
      onClickCostCell
    );

  return {
    baseCostValidationError,
    isValidMfrRegionOption,
    getDataIndexes,
    getMfrRegionOptions,
    onUpdateAvailability,
    onUpdateAvailabilityValue,
    onUpdateCost,
    onUpdateCostValue,
    onUpdateLeadTime,
    onUpdateLeadTimeValue,
    onUpdateTable,
  };
};
