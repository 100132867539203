import { Ref, nextTick } from "vue";
import { AxiosResponse } from "axios";
import useBaseCostMethods from "@/composables/costing-parameters/useBaseCostMethods";
import { SalesRegionInterface } from "@/types/SalesRegionInterface";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";
import AvailabilityInterface from "@/types/admin-parameters/costing-parameters/AvailabilityInterface";

export default (
  data: Ref<AvailabilityInterface[]>,
  keyField: string,
  addRequest: (payload: BaseCostInterface[]) => Promise<AxiosResponse>,
  changeRequest: (payload: BaseCostInterface) => Promise<AxiosResponse>,
  onClickLeadTimeCell: () => void
) => {
  const {
    isValidMfrRegionOption,
    getDataIndexes,
    getMfrRegionOptions,
    onUpdateTable,
  } = useBaseCostMethods(data);

  const onUpdateCost = (
    newCost: string,
    row: AvailabilityInterface,
    region: SalesRegionInterface
  ): Promise<void> | void => {
    const index = row.baseCosts.findIndex(
      (item) => item.mfrRegionId === region.id
    );
    const { available, leadTime } = getMfrRegionOptions(row.id, region.id);
    const { dataIndex, baseCostsIndex } = getDataIndexes(row.id, region.id);
    if (available && !row.baseCosts[index]?.id && !!leadTime) {
      return addRequest([
        {
          [keyField]: row.id,
          available: true,
          cost: `${newCost}`,
          leadTime: `${leadTime}`,
          mfrRegionId: region.id,
        },
      ]).then((res) => {
        onUpdateTable(dataIndex, baseCostsIndex, {
          ...res.data.data[0],
          mfrRegionId: region.id,
        });
      });
    }
    if (index !== -1 && row.baseCosts[index]?.id && available && !!leadTime) {
      return changeRequest({
        [keyField]: row.id,
        id: row.baseCosts[index].id,
        available: true,
        cost: `${newCost}`,
        leadTime: `${leadTime}`,
        mfrRegionId: region.id,
      }).then((res) => {
        onUpdateTable(dataIndex, baseCostsIndex, {
          ...res.data.data,
          mfrRegionId: region.id,
        });
      });
    }
  };

  const onUpdateCostValue = (
    newVal: string,
    row: AvailabilityInterface,
    region: SalesRegionInterface
  ) => {
    const { leadTime } = getMfrRegionOptions(row.id, region.id);
    const { dataIndex, baseCostsIndex } = getDataIndexes(row.id, region.id);
    if (baseCostsIndex !== -1) {
      data.value[dataIndex].baseCosts[baseCostsIndex].cost = newVal;
    } else {
      data.value[dataIndex].baseCosts.push({
        cost: newVal,
        leadTime:
          data.value[dataIndex].baseCosts[baseCostsIndex]?.leadTime || "",
        mfrRegionId: region.id,
      });
    }
    if (!isValidMfrRegionOption(row.id, region.id) && !leadTime) {
      nextTick(() => {
        onClickLeadTimeCell();
      });
    }
  };

  return {
    onUpdateCost,
    onUpdateCostValue,
  };
};
