import { AxiosResponse } from "axios";
import ApiService from "@/services";
import { NewRawMaterialInterface } from "@/types/costing-parameters/NewRawMaterialInterface";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";

export default class CostingParameterService {
  static getMfrRegions(): Promise<AxiosResponse> {
    return ApiService.get("/v1/mfr-regions");
  }

  //Raw Materials
  static getRawMaterials(): Promise<AxiosResponse> {
    return ApiService.get("/v2/raw-materials/with-relations");
  }
  static getListOfRawMaterials(params: {
    search?: string;
    skip: number;
    limit: number;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/raw-materials", { params });
  }
  static addRawMaterial(
    payload: NewRawMaterialInterface
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/raw-materials", payload);
  }
  static changeRawMaterial(payload: {
    rawMaterialId: string;
    data: Partial<NewRawMaterialInterface>;
  }): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/raw-materials/${payload.rawMaterialId}`,
      payload.data
    );
  }
  static deleteRawMaterial(rawMaterialId: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/raw-materials/${rawMaterialId}`);
  }

  static addRawMaterialAlternatives(payload: {
    rawMaterialId: string;
    alternativeMaterialIds: string[];
  }): Promise<AxiosResponse> {
    return ApiService.post(
      `/v2/raw-materials/${payload.rawMaterialId}/alternatives`,
      { alternativeMaterialIds: payload.alternativeMaterialIds }
    );
  }
  static deleteAllRawMaterialAlternatives(
    rawMaterialId: string,
    alternativeMaterialIds: string[]
  ): Promise<AxiosResponse> {
    return ApiService.delete(
      `/v2/raw-materials/${rawMaterialId}/alternatives`,
      { data: { alternativeMaterialIds } }
    );
  }

  static addRawMaterialBaseCost(
    payload: BaseCostInterface[]
  ): Promise<AxiosResponse> {
    return ApiService.post(
      `/v2/raw-materials/${payload[0].rawMaterialId}/base-costs`,
      payload
    );
  }
  static changeRawMaterialBaseCost(
    payload: BaseCostInterface
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/raw-materials/${payload.rawMaterialId}/base-costs/${payload.id}`,
      {
        available: payload.available,
        cost: payload.cost,
        leadTime: payload.leadTime,
      }
    );
  }

  //Raw Materials Group
  static getRawMaterialGroup(): Promise<AxiosResponse> {
    return ApiService.get("/v2/raw-material-groups");
  }
  static addRawMaterialGroup(name: string): Promise<AxiosResponse> {
    return ApiService.post("/v2/raw-material-groups", {
      name,
    });
  }
  static changeRawMaterialGroup(params: {
    id: string;
    name: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/raw-material-groups/${params.id}`, {
      name: params.name,
    });
  }
  static deleteRawMaterialGroup(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/raw-material-groups/${id}`);
  }
}
