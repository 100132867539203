import { h, Ref } from "vue";
import { NInput, SelectOption } from "naive-ui";

export default (
  selectInForm: boolean,
  filterable: boolean,
  addable: boolean,
  options: SelectOption[],
  selectorOptions: Ref,
  isEdit: Ref,
  newValueKey: string,
  inputRef: Ref,
  cellRef: Ref,
  emit: (
    event:
      | "addOption"
      | "deleteOption"
      | "changeOption"
      | "editMode"
      | "keydownTab"
      | "keydownEsc",
    ...args: any[]
  ) => void
) => {
  const renderHeader = () => () =>
    h(
      "div",
      {
        class: ["selector-header", selectInForm ? "select-in-form" : ""],
      },
      [
        h("button", {
          class: "close-button",
          onClick: () => {
            isEdit.value = false;
          },
        }),
        h(NInput, {
          class: ["selector-search", "input-borderless"],
          ref: inputRef,
          placeholder: filterable
            ? addable
              ? "Search or Add"
              : "Search"
            : "Select",
          onInput: (value: string) => {
            selectorOptions.value = options.filter((item: SelectOption) =>
              (item.label as string).toLowerCase().includes(value.toLowerCase())
            );
            if (addable) {
              const index = selectorOptions.value.findIndex(
                (item: { label: string; value: string }) => item.label === value
              );
              if (index !== -1 || !value) {
                return;
              }
              selectorOptions.value.push({
                label: value,
                value: `${newValueKey}${value}`,
                class: "new-value",
              });
            }
          },
          onKeydown: (e: KeyboardEvent) => {
            if (e.code === "Space") {
              e.stopPropagation();
            } else if (e.code === "Escape") {
              isEdit.value = false;
              emit("keydownEsc");
              e.stopPropagation();
              e.preventDefault();
            } else if (e.code === "Tab") {
              isEdit.value = false;
              emit("keydownTab", { cellRef, shiftKey: e.shiftKey });
              e.preventDefault();
            }
          },
        }),
      ]
    );
  return { renderHeader };
};
