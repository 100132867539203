import { NButton, NDropdown, NIcon, NInput, SelectOption } from "naive-ui";
import { h, nextTick, Ref, ref, VNodeChild } from "vue";
import CostingParameterService from "@/services/CostingParameterService";
import ThreeDotMenu from "@/components/icons/ThreeDotMenu.vue";
import loadingComposable from "@/composables/loadingComposable";
import { onFocusElement } from "@/composables/onFocusElement";

export default (
  inputId: Ref,
  newChangeableInputValue: Ref,
  isEdit: Ref,
  rowOptions: Ref,
  onUpdateEmit: (newData: { id: string; name: string }) => void,
  onDeleteEmit: (optionValue: string) => void
) => {
  const changeInputRef = ref<HTMLInputElement>();
  const { startLoader, finishLoader } = loadingComposable();
  const renderLabel = (option: SelectOption): VNodeChild => {
    return option.value === inputId.value
      ? [
          h(NInput, {
            class: ["editable-option", "input-borderless"],
            ref: changeInputRef,
            defaultValue: `${option.label}`,
            modelValue: newChangeableInputValue.value,
            onKeydown: (e: KeyboardEvent) => {
              if (e.code === "Space") {
                e.stopPropagation();
              }
            },
            onChange: (value: string) => {
              newChangeableInputValue.value = value;
            },
            onBlur: (e?: FocusEvent) => {
              const target = e
                ? (e.relatedTarget as HTMLElement) || (e.target as HTMLElement)
                : undefined;
              if (!target?.classList.contains("save-option-changes-button")) {
                inputId.value = "";
                isEdit.value = false;
              }
            },
            onClick: (e: Event) => {
              e.stopPropagation();
            },
          }),
          h(
            NButton,
            {
              class: ["save-option-changes-button", "text-button"],
              onClick: (e: Event) => {
                e.stopPropagation();
                startLoader();
                CostingParameterService.changeRawMaterialGroup({
                  id: `${option.value}`,
                  name: newChangeableInputValue.value,
                })
                  .then((res) => {
                    onUpdateEmit(res.data.data);
                    inputId.value = "";
                  })
                  .finally(() => {
                    finishLoader();
                  });
              },
            },
            () => "Save"
          ),
        ]
      : [
          option.label as string,
          h(
            NDropdown,
            {
              options: rowOptions.value,
              placement: "bottom-end",
              trigger: "click",
              onClick: (e: Event) => {
                e.stopPropagation();
              },
              onSelect: (key: string) => {
                if (key === "edit") {
                  inputId.value = option.value as string;
                  newChangeableInputValue.value = option.label as string;
                  nextTick(() => {
                    onFocusElement(changeInputRef);
                  });
                } else if (key === "delete") {
                  startLoader();
                  CostingParameterService.deleteRawMaterialGroup(
                    `${option.value}`
                  )
                    .then(() => {
                      onDeleteEmit(`${option.value}`);
                    })
                    .finally(() => {
                      finishLoader();
                    });
                }
              },
            },
            {
              default: () =>
                h(
                  NButton,
                  {
                    class: "dropdown-menu-button",
                    tabindex: -1,
                    onClick: (e: Event) => {
                      e.stopPropagation();
                    },
                  },
                  () => h(NIcon, () => h(ThreeDotMenu, { size: 18 }))
                ),
            }
          ),
        ];
  };
  return { renderLabel };
};
