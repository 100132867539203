import { Ref, nextTick } from "vue";
import { AxiosResponse } from "axios";
import useBaseCostMethods from "@/composables/costing-parameters/useBaseCostMethods";
import { SalesRegionInterface } from "@/types/SalesRegionInterface";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";
import AvailabilityInterface from "@/types/admin-parameters/costing-parameters/AvailabilityInterface";

export default (
  data: Ref<AvailabilityInterface[]>,
  keyField: string,
  addRequest: (payload: BaseCostInterface[]) => Promise<AxiosResponse>,
  changeRequest: (payload: BaseCostInterface) => Promise<AxiosResponse>,
  onClickCostCell: () => void
) => {
  const {
    isValidMfrRegionOption,
    getDataIndexes,
    getMfrRegionOptions,
    onUpdateTable,
  } = useBaseCostMethods(data);

  const onUpdateLeadTime = (
    newLeadTime: string,
    row: AvailabilityInterface,
    region: SalesRegionInterface
  ): Promise<void> | void => {
    const index = row.baseCosts.findIndex(
      (item) => item.mfrRegionId === region.id
    );
    const { available, cost } = getMfrRegionOptions(row.id, region.id);
    const { dataIndex, baseCostsIndex } = getDataIndexes(row.id, region.id);
    if (!row.baseCosts[index]?.id && available && !!cost) {
      return addRequest([
        {
          [keyField]: row.id,
          available: true,
          leadTime: `${newLeadTime}`,
          cost: `${cost}`,
          mfrRegionId: region.id,
        },
      ]).then((res) => {
        onUpdateTable(dataIndex, baseCostsIndex, {
          ...res.data.data[0],
          mfrRegionId: region.id,
        });
      });
    }
    if (index !== -1 && row.baseCosts[index]?.id && available && !!cost) {
      return changeRequest({
        [keyField]: row.id,
        id: row.baseCosts[index].id,
        available: true,
        cost: `${cost}`,
        leadTime: `${newLeadTime}`,
        mfrRegionId: region.id,
      }).then((res) => {
        onUpdateTable(dataIndex, baseCostsIndex, {
          ...res.data.data,
          mfrRegionId: region.id,
        });
      });
    }
  };

  const onUpdateLeadTimeValue = (
    newVal: string,
    row: AvailabilityInterface,
    region: SalesRegionInterface
  ) => {
    const { cost } = getMfrRegionOptions(row.id, region.id);
    const { dataIndex, baseCostsIndex } = getDataIndexes(row.id, region.id);
    if (baseCostsIndex !== -1) {
      data.value[dataIndex].baseCosts[baseCostsIndex].leadTime = newVal;
    } else {
      data.value[dataIndex].baseCosts.push({
        cost: data.value[dataIndex].baseCosts[baseCostsIndex]?.cost || "",
        leadTime: newVal,
        mfrRegionId: region.id,
      });
    }
    if (!isValidMfrRegionOption(row.id, region.id) && !cost) {
      nextTick(() => {
        onClickCostCell();
      });
    }
  };

  return {
    onUpdateLeadTime,
    onUpdateLeadTimeValue,
  };
};
