import { nextTick, ref, Ref } from "vue";

export default (tableRef: Ref<HTMLElement | undefined>) => {
  const isEditModeCell = ref(false);
  const focusResetCounter = ref(0);
  const blurRowId = ref("");
  const tabRowId = ref("");
  const tabRowKey = ref("");
  const shiftKey = ref(false);

  const selectableTableCellProps = () => {
    return {
      tableRef,
      editMode: isEditModeCell.value,
      focusResetCounter: focusResetCounter.value,
      blurRowId: blurRowId.value,
      tabRowId: tabRowId.value,
      tabRowKey: tabRowKey.value,
      shiftKey: shiftKey.value,
      childClass: "selectable-table-cell",
    };
  };
  const editableTableCellProps = () => {
    return {
      tableRef,
      editMode: isEditModeCell.value,
      focusResetCounter: focusResetCounter.value,
      childClass: "editable-table-cell",
    };
  };

  const onKeydownEsc = (id: string) => {
    blurRowId.value = "";
    nextTick(() => {
      blurRowId.value = id;
    });
  };
  const onKeydownTab = (
    e: { shiftKey: boolean },
    id: string,
    rowKey: string
  ) => {
    shiftKey.value = !shiftKey.value;
    tabRowId.value = "";
    tabRowKey.value = "";
    nextTick(() => {
      shiftKey.value = e.shiftKey;
      tabRowId.value = id;
      tabRowKey.value = rowKey;
    });
  };
  const onFocusReset = () => {
    focusResetCounter.value += focusResetCounter.value % 2 === 0 ? 1 : -1;
  };

  return {
    isEditModeCell,
    focusResetCounter,
    blurRowId,
    tabRowId,
    tabRowKey,
    shiftKey,
    selectableTableCellProps,
    editableTableCellProps,
    onKeydownEsc,
    onKeydownTab,
    onFocusReset,
  };
};
