import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-091d485d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["focusable-table-cell", { focused: $setup.isFocused }]),
      ref: "container",
      tabindex: "0",
      onFocus: _cache[0] || (_cache[0] = ($event: any) => ($setup.isFocused = true)),
      onKeydown: $setup.onKeydown,
      onClick: $setup.onClick
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 34)
  ]))
}