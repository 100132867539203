import { Ref, ref } from "vue";
import { BaseCostInterface } from "@/types/costing-parameters/BaseCostInterface";
import AvailabilityInterface from "@/types/admin-parameters/costing-parameters/AvailabilityInterface";

export default (data: Ref<AvailabilityInterface[]>) => {
  const baseCostValidationError = ref(false);

  const isValidMfrRegionOption = (rowId: string, regionId: string) => {
    const { available, cost, leadTime } = getMfrRegionOptions(rowId, regionId);
    return !available || !!(available && cost && leadTime);
  };
  const getDataIndexes = (rowId: string, regionId: string) => {
    const dataIndex = data.value.findIndex(
      (item: AvailabilityInterface) => item.id === rowId
    );
    const baseCostsIndex =
      dataIndex !== -1
        ? data.value[dataIndex].baseCosts.findIndex(
            (item) => item.mfrRegionId === regionId
          )
        : -1;
    return { dataIndex, baseCostsIndex };
  };
  const getMfrRegionOptions = (rowId: string, regionId: string) => {
    const { dataIndex, baseCostsIndex } = getDataIndexes(rowId, regionId);
    const available =
      !!data.value[dataIndex]?.baseCosts[baseCostsIndex]?.available;
    const cost = data.value[dataIndex]?.baseCosts[baseCostsIndex]?.cost;
    const leadTime = data.value[dataIndex]?.baseCosts[baseCostsIndex]?.leadTime;
    return { available, cost, leadTime };
  };

  const onUpdateTable = (
    dataIndex: number,
    baseCostsIndex: number,
    newBaseCost: BaseCostInterface
  ) => {
    if (baseCostsIndex !== -1) {
      data.value[dataIndex].baseCosts[baseCostsIndex] = newBaseCost;
    } else {
      data.value[dataIndex].baseCosts.push(newBaseCost);
    }
  };

  return {
    baseCostValidationError,
    isValidMfrRegionOption,
    getDataIndexes,
    getMfrRegionOptions,
    onUpdateTable,
  };
};
